<template>
	<v-container fluid>
		<StoryComponent />
	</v-container>
</template>

<script>
import StoryComponent from '@/components/story/Index.vue';

export default {
	name: 'Story',
	components: {
		StoryComponent,
	},
};
</script>

<template>
	<v-col offset-md="2" xl="6" md="8" sm="12" cols="12">
		<v-card class="shadow-blue pa30">
			<h2>Performance à l'entrainement</h2>
			<span v-if="score * 100 >= randomNumber" class="ortho-text-gray"
				>Ton taux de bonnes réponses est au-dessus de la moyenne, continue comme ça !
			</span>
			<span v-else class="ortho-text-gray">Ton taux de bonnes réponses est en-dessous de la moyenne, persévère ! </span>
			<PerformanceChart :totalScoreInPercent="score * 100" />
		</v-card>
		<div class="d-flex justify-center mt-5 flex-column flex-md-row">
			<v-btn class="hvr-grow actionButton mb-2 mb-md-0 mr-0 mr-md-4" dark large @click="refreshPage()">
				Refaire l'entrainement</v-btn
			>
			<v-btn
				:to="'/dashboard/rules/' + $route.params.idFormation + '?rules=' + this.$route.params.idRules"
				class="hvr-grow actionButton"
				outlined
				large
			>
				Revoir la règle</v-btn
			>
		</div>
	</v-col>
</template>

<script>
import PerformanceChart from '../performance-chart/Index';

export default {
	name: 'ResultQuestionsRules',
	components: {
		PerformanceChart,
	},
	data() {
		return {
			score: 0,
		};
	},
	props: {
		answersGiven: Array,
	},
	async mounted() {
		window.scrollTo(0, 0);
		let goodAnswer = 0;
		this.answersGiven.map((element) => {
			if (element.is_good_answer == true) {
				goodAnswer++;
			}
		});
		this.score = goodAnswer / this.answersGiven.length;
		await this.$store.dispatch('rules/postTrainingQuestions', {
			score: this.score,
			idFormation: this.$route.params.idFormation,
			idRules: this.$route.params.idRules,
			answers: this.answersGiven,
		});
	},
	computed: {
		randomNumber() {
			const min = 60;
			const max = 70;
			var number = Math.floor(Math.random() * (max - min + 1)) + min;
			return number;
		},
	},
	methods: {
		refreshPage() {
			location.reload();
		},
	},
};
</script>

<style lang="scss" scoped>
a {
	color: black !important;
	text-decoration: inherit;
}
</style>

<template>
	<div>
		<div
			v-if="!responded"
			:id="'imageFormatA' + question._id"
			class="imageFormatA-container"
			title="Réponds à la question pour voir l'astuce"
		>
			<img src="@/assets/images/coverTeasing.png" class="ortho-image-format-a" />
		</div>
		<div v-else :id="'expansionPanelsDiv' + question" class="shadow-blue paddingPanel">
			<div>
				<div class="ortho-questions-cours-title">
					<v-row class="font-weight-bold text-center">
						<v-col md="12" sm="12" cols="12" class="text-center">
							{{ message }}
						</v-col>
					</v-row>
				</div>
				<div v-for="(cour, index) in question.cours_id" :key="'description-' + index">
					<div class="ortho-questions-cours-description">
						<v-row>
							<v-col md="12" sm="12" cols="12">
								<span v-html="cour.description"></span>
							</v-col>
						</v-row>
					</div>
				</div>
				<div class="mt20 pt20">
					<div
						class="ortho-entrainement-button hvr-shrink"
						v-if="count < allQuestions.length"
						@click="goToNextquestionA()"
					>
						Refaire un entrainement
					</div>
					<div class="ortho-entrainement-button hvr-shrink" v-else @click="goToResult()">Voir mes résultats</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Training',
	props: {
		question: Object,
		message: String,
		responded: Boolean,
		allQuestions: Array,
		count: Number,
	},
	data() {
		return {
			panel: [0],
		};
	},
	mounted() {
		window.scrollTo(0, 0);
	},
	methods: {
		goToNextquestionA() {
			this.$emit('ChangeTrainingQuestion');

			this.panel = [null];

			//for mobile : always display top of the page
			window.scrollTo(0, 0);
		},
		goToResult() {
			this.$emit('goToResult');
		},
	},
};
</script>
<style lang="scss" scoped>
.paddingPanel {
	padding: 24px 16px;
}
</style>

<template>
	<v-container fluid>
		<RulesTrainingComponent />
	</v-container>
</template>

<script>
import RulesTrainingComponent from '@/components/rules-training/Index';

export default {
	name: 'RulesTraining',
	components: {
		RulesTrainingComponent,
	},
};
</script>

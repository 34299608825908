<template>
	<div
		:class="{
			pa5: $vuetify.breakpoint.smAndDown,
			palr5: $vuetify.breakpoint.mdAndUp,
		}"
	>
		<div v-if="!showResult">
			<component
				:is="initComponent"
				:dataId="this.$route.params.idStory"
				:infosStory="infosStoryDisplay"
				:countStory="countStory"
				@displayResults="displayResults()"
			/>
		</div>
		<div v-else>
			<ResultsStory :stories="stories" />
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import ResultsStory from '../exercice/results/Default';

export default {
	name: 'Index',
	data() {
		return {
			initComponent: '',
			countStory: null,
			showResult: false,
			infosStoryDisplay: null,
		};
	},
	components: {
		ResultsStory,
	},
	computed: {
		...mapGetters('exercice', {
			listStories: 'listStories',
			stories: 'stories',
		}),
		...mapGetters('profile', { formationProgress: 'formationProgress' }),
	},
	async created() {
		try {
			if (this.formationProgress == null)
				this.$store.dispatch('profile/formationProgress', { idFormation: this.$route.params.idFormation });

			await this.$store.dispatch('exercice/getExercice', {
				idFormation: this.$route.params.idFormation,
				idExercice: this.$route.params.idExercice,
			});

			this.initComponent = async () => await import(`../exercice/story-types/Default`);
			this.countStory = this.listStories.findIndex((element) => {
				return element._id == this.$route.params.idStory;
			});
			this.infosStoryDisplay = this.stories[this.countStory];
			if (document.getElementById('menu-nav')) document.getElementById('menu-nav').style.display = 'none';
			if (document.getElementById('menu-nav-mobile')) document.getElementById('menu-nav-mobile').style.display = 'none';
		} catch (err) {
			this.error = true;
		}
	},
	methods: {
		displayResults() {
			this.showResult = true;
			if (document.getElementById('menu-nav')) document.getElementById('menu-nav').style.display = 'block';
			if (document.getElementById('menu-nav-mobile'))
				document.getElementById('menu-nav-mobile').style.display = 'block';
		},
	},
};
</script>

<style></style>

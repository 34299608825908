<template>
	<div>
		<Error v-if="error"></Error>
		<div
			v-else
			:class="{
				pa5: $vuetify.breakpoint.smAndDown,
				palr5: $vuetify.breakpoint.mdAndUp,
			}"
		>
			<QuestionsRules :questions="questionsRules" />
		</div>
	</div>
</template>

<script>
import Error from '../../views/layouts/Error';
import { mapGetters } from 'vuex';
import QuestionsRules from './QuestionsRules';

export default {
	name: 'RulesTrainingComponent',
	components: {
		Error,
		QuestionsRules,
	},
	computed: {
		...mapGetters('rules', {
			questionsRules: 'questionsRules',
		}),
	},
	data() {
		return {
			error: false,
		};
	},
	async created() {
		try {
			await this.$store.dispatch('rules/getTrainingQuestions', {
				idRules: this.$route.params.idRules,
			});
		} catch (err) {
			this.error = true;
		}
	},
};
</script>
